import { Box, ThemeProvider } from '@mui/material';
import React from 'react';
import { Provider } from 'react-redux';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { NotificationProvider } from './components/contexts/NotificationContext';
import { Call } from './components/pages/Call';
import Cms from './components/pages/Cms';
import { ForgotPasswordForm } from './components/pages/ForgotPasswordForm';
import LoginForm from './components/pages/LoginForm';
import { ResetPasswordForm } from './components/pages/ResetPasswordForm';
import SignUpForm from './components/pages/SignUpForm';
import ProtectedRoute from './components/routing/ProtectedRoute';
import { PublicRoute } from './components/routing/PublicRoute';
import SessionManager from './components/routing/SessionManager';
import { store } from './state/state';
import theme from './theme/theme';

const App: React.FC = () => {
  return (
    <Box className="App" sx={{ display: 'flex', backgroundColor: '#fff' }}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <NotificationProvider>
            <SessionManager>
              <Router>
                <Routes>
                  <Route element={<PublicRoute />}>
                    <Route path="/signup" element={<SignUpForm />} />
                    <Route path="/login" element={<LoginForm />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPasswordForm />}
                    />
                    <Route
                      path="/reset-password"
                      element={<ResetPasswordForm />}
                    />
                  </Route>
                  <Route element={<ProtectedRoute />}>
                    <Route path="/matters/:matter_id?" element={<Cms />} />
                    <Route path="/call/:id" element={<Call />} />
                    <Route path="*" element={<Navigate to="/matters/" />} />
                  </Route>
                </Routes>
              </Router>
            </SessionManager>
          </NotificationProvider>
        </ThemeProvider>
      </Provider>
    </Box>
  );
};

export default App;
