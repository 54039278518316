import { Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  useCreateEngagementLetterMutation,
  useGetEngagementFieldsQuery,
} from '../../services/api/documentService';
import { EngagementLetterField } from '../../services/types/document-types';
import {
  getHumanReadableFieldName,
  populateEngagementFieldsWithDefaults,
} from '../../utils/engagement-letter';
import { SecondaryButton } from '../base/Buttons';
import { useNotification } from '../contexts/NotificationContext';

const EngagementLetterEmailInput = ({
  onChange,
}: {
  onChange: (fieldId: string, value: string) => void;
}) => {
  return (
    <TextField
      label="Email"
      id="email"
      name="email"
      autoComplete="email"
      onChange={(e) => onChange('client_email_address', e.target.value)}
      inputProps={{
        pattern: '[^\\s@]+@[^\\s@]+\\.[^\\s@]+',
      }}
    />
  );
};

const EngagementLetterFieldInput = ({
  field,
  onChange,
}: {
  field: EngagementLetterField;
  onChange: (fieldId: string, value: string) => void;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(field.display_name, e.target.value);
  };

  switch (field.display_name) {
    case 'client_email_address':
      return <EngagementLetterEmailInput onChange={onChange} />;
    default:
      return (
        <TextField
          label={getHumanReadableFieldName(field.display_name)}
          onChange={handleChange}
        />
      );
  }
};

const useEngagementFields = (matterId: string) => {
  const [engagementFields, setEngagementFields] = useState<
    EngagementLetterField[]
  >([]);
  const { data: fetchedEngagementFields, isLoading } =
    useGetEngagementFieldsQuery(matterId);

  useEffect(() => {
    if (fetchedEngagementFields) {
      setEngagementFields(
        populateEngagementFieldsWithDefaults(fetchedEngagementFields),
      );
    }
  }, [fetchedEngagementFields]);
  return { engagementFields, isLoading };
};

const CreateEngagementLetterForm = ({
  matterId,
  formData,
  setFormData,
}: {
  matterId: string;
  formData: Record<string, string>;
  setFormData: (formData: Record<string, string>) => void;
}) => {
  const { engagementFields, isLoading } = useEngagementFields(matterId);

  const handleFormDataChange = (fieldId: string, value: string) => {
    setFormData({ ...formData, [fieldId]: value });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Stack direction="column" gap={4}>
      {engagementFields?.map((field) => (
        <EngagementLetterFieldInput
          key={field.id}
          field={field}
          onChange={handleFormDataChange}
        />
      ))}
    </Stack>
  );
};

export const CreateEngagementLetter = ({
  matterId,
  setOpen,
}: {
  matterId: string;
  setOpen: (open: boolean) => void;
}) => {
  const notify = useNotification();
  const [
    createEngagementLetter,
    { isLoading: isCreatingEngagementLetter, isError, isSuccess },
  ] = useCreateEngagementLetterMutation();

  const [formData, setFormData] = useState<Record<string, string>>({});

  useEffect(() => {
    if (isSuccess) {
      notify('Engagement letter created', 'success', 3000);
      setOpen(false);
    }
    if (isError) {
      notify('Error creating engagement letter', 'error', 3000);
    }
  }, [isSuccess, isError, notify, setOpen]);

  return (
    <Stack direction="column" gap={4}>
      <CreateEngagementLetterForm
        matterId={matterId}
        formData={formData}
        setFormData={setFormData}
      />
      <SecondaryButton
        onClick={() => createEngagementLetter({ matterId, fields: formData })}
        disabled={isCreatingEngagementLetter}
      >
        Send Retainer
      </SecondaryButton>
    </Stack>
  );
};
