import { Conversation } from '../services/types/client-intake-types';

export function formatCallDuration(
  currentTimeSeconds: number,
  totalDurationSeconds: number,
): string {
  const formatTime = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    const formattedHours = hours > 0 ? `${hours}:` : '';
    const formattedMinutes = `${minutes < 10 && hours > 0 ? '0' : ''}${minutes}:`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedHours}${formattedMinutes}${formattedSeconds}`;
  };

  const currentTimeFormatted = formatTime(currentTimeSeconds);
  const totalDurationFormatted = formatTime(totalDurationSeconds);
  return currentTimeSeconds > 0
    ? `${currentTimeFormatted}`
    : totalDurationFormatted;
}

export function formatIntakeCall(conversation: Conversation): string {
  if (!conversation) {
    return '';
  }
  const { start_timestamp } = conversation;
  if (!start_timestamp) {
    return '';
  }

  const date = new Date(start_timestamp);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString('en-US', options);
  //  TODO add firm user name
  return `${formattedDate}`;
}
