import { Box, Container, TextField } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useResetPasswordMutation } from '../../services/api/userService';
import { SecondaryButton } from '../base/Buttons';
import FinchLogo from '../base/FinchLogo';
import { useNotification } from '../contexts/NotificationContext';

interface ResetPasswordFormState {
  password1: string;
  password2: string;
}

export const ResetPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const notify = useNotification();
  const [resetPassword] = useResetPasswordMutation();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState<ResetPasswordFormState>({
    password1: '',
    password2: '',
  });
  const [error, setError] = useState<string | null>(null);
  const token = searchParams.get('token');

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formState.password1 !== formState.password2) {
      setError('Passwords do not match');
      return;
    }

    setLoading(true);

    try {
      await resetPassword({ password: formState.password1, token });
      notify('Password reset successfully', 'success', 3000);
      navigate('/login');
    } catch (e) {
      Sentry.captureException(e);
      notify('Failed to reset password', 'error', 3000);
    } finally {
      setLoading(false);
    }
  };

  const handleChange =
    (prop: keyof ResetPasswordFormState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormState({ ...formState, [prop]: event.target.value });
    };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <FinchLogo width="160px" />

        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            type="password"
            name="password"
            autoComplete="password"
            autoFocus
            value={formState.password1}
            onChange={handleChange('password1')}
            helperText={error || ''}
            inputProps={{
              minLength: 8,
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password-again"
            label="Password Again"
            type="password"
            id="password-again"
            autoComplete="password-again"
            value={formState.password2}
            onChange={handleChange('password2')}
            helperText={error || ''}
            inputProps={{
              minLength: 8,
            }}
          />

          <SecondaryButton
            type="submit"
            fullWidth
            disableRipple
            disabled={loading}
            variant="contained"
            sx={{ mt: 4, mb: 2 }}
          >
            Reset Password
          </SecondaryButton>
        </Box>
      </Box>
    </Container>
  );
};
