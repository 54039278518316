import {
  Box,
  Button,
  Grid,
  Input,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import useMatterSelection from '../../hooks/useMatterSelection';
import { useUpdateFormFieldMutation } from '../../services/api/mattersService';
import theme from '../../theme/theme';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          p={3}
          sx={{
            // background: theme.palette.backgroundGray.main,
            border: `1.5px solid ${theme.palette.border.main}`,
            borderTop: 'none',
            borderRadius: '0 0 8px 8px',
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const IntakeForm: React.FC = () => {
  const { selectedMatterData } = useMatterSelection();
  const intakeForm = selectedMatterData?.form;
  const [value, setValue] = useState(0);
  const [formBlob, setFormBlob] = useState<Blob | null>(null);
  const [key, setKey] = React.useState(0);

  const [updateFormField] = useUpdateFormFieldMutation();

  useEffect(() => {
    setValue(0);
    setKey((prevKey) => prevKey + 1);
  }, []);

  useEffect(() => {
    const fetchPdf = async () => {
      if (selectedMatterData?.form?.id) {
        try {
          const response = await fetch(
            `${import.meta.env.VITE_APP_DJANGO_BACKEND_HOST}/api/documents/form/${selectedMatterData.form.id}`,
            {
              credentials: 'include',
            },
          );

          if (!response.ok) {
            throw new Error('Failed to fetch the PDF');
          }

          const blob = await response.blob();
          setFormBlob(blob);
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    };

    fetchPdf();
  }, [selectedMatterData?.form?.id]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleFormFieldChange = async (
    matterId: string,
    formFieldId: string,
    oldValue: string | null,
    newValue: string,
  ) => {
    if ((!newValue && !oldValue) || newValue === oldValue) return;
    try {
      const patch = {
        answer_data: newValue,
      };
      await updateFormField({
        matter_id: matterId,
        id: formFieldId,
        patch,
      }).unwrap();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  if (!intakeForm) {
    return null;
  }

  return (
    <Box>
      <Box
        key={key}
        sx={{
          width: '100%',
          // borderBottom: `1px solid ${theme.palette.border.main}`,
          paddingBottom: 3,
        }}
      >
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Typography variant="h2">Intake Form</Typography>
          {formBlob && (
            <Button
              variant="outlined"
              color="info"
              href={URL.createObjectURL(formBlob)}
              target="_blank"
              download={`${selectedMatterData.name}_form.pdf`}
              rel="noreferrer"
              sx={{
                border: `1px solid ${theme.palette.border.dark}`,
                fontFamily: 'area-normal, sans-serif',
                fontSize: '12px',
                fontWeight: '700',
                letterSpacing: '1px',
              }}
            >
              Download Form
            </Button>
          )}
        </Stack>
      </Box>
      <Box
        sx={{
          border: '1.5px solid',
          borderColor: 'divider',
          borderRadius: '8px 8px 0 0',
          background: theme.palette.backgroundGray.main,
          overflow: 'hidden',
        }}
      >
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="scrollable intake form tabs"
          textColor="secondary"
          indicatorColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            style: {
              backgroundColor: 'black', // Set your custom color here
            },
          }}
        >
          {[...intakeForm.form_pages]
            ?.sort((a, b) => {
              if (a.order === null) return 1; // Place null values at the end
              if (b.order === null) return -1;
              return a.order - b.order;
            })
            .map((page, index) => (
              <Tab
                disableRipple
                key={index}
                label={page.title}
                id={`tab-${index}`}
                sx={{
                  fontFamily: 'area-normal, sans-serif',
                  fontWeight: '700',
                  letterSpacing: '0px',
                  textTransform: 'none',
                  background: theme.palette.backgroundGray.main,
                  color: 'info.light',
                  '&.Mui-selected': {
                    color: 'black',
                    borderColor: 'black',
                  },
                }}
              />
            ))}
        </Tabs>
      </Box>

      {intakeForm.form_pages?.map((page, pageIndex) => (
        <TabPanel value={value} index={pageIndex} key={pageIndex}>
          <Grid
            container
            spacing={2}
            columns={{ xs: 3, sm: 8, md: 12 }}
            pl={2}
            py={2}
            rowSpacing={4}
          >
            {[...page.form_fields]
              .sort((a, b) => a.order - b.order)
              .map((field) => (
                <Grid
                  item
                  xs={2}
                  sm={4}
                  md={4}
                  key={field.id}
                  // sx={{ mt: '32px', p: '8px' }}
                >
                  <Stack direction="column" spacing="0px">
                    <Typography variant="callout" color="info.light">
                      {field.title}
                    </Typography>
                    <Input
                      multiline
                      defaultValue={field.answer_data}
                      onBlur={(e) => {
                        handleFormFieldChange(
                          selectedMatterData.id,
                          field.id,
                          field.answer_data,
                          e.target.value,
                        );
                      }}
                      sx={{
                        ...theme.typography.body2,
                        width: '100%',
                        fontWeight: 600,
                        p: '8px',
                        '&.MuiInput-root': {
                          marginLeft: '-8px',
                          border: 'none',
                        },
                        '&.MuiInput-root::before': {
                          content: 'none', // Remove the black bar (pseudo-element)
                        },
                        '&.MuiInput-root::after': {
                          content: 'none', // Remove the black bar (pseudo-element)
                        },
                        '&:hover': {
                          background: theme.palette.backgroundGray.dark,
                          borderRadius: '8px',
                          cursor: 'pointer',
                        },
                        '&:hover::before': {
                          content: 'none', // Prevent black bar on hover
                        },
                        '&.Mui-focused::before': {
                          content: 'none', // Prevent black bar on focus
                        },
                        '&.Mui-focused::after': {
                          content: 'none', // Prevent black bar on focus
                        },
                      }}
                    />
                  </Stack>
                </Grid>
              ))}
          </Grid>
        </TabPanel>
      ))}
    </Box>
  );
};

export default IntakeForm;
