// eslint-disable-next-line import/no-extraneous-dependencies
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetMatterDetailsQuery,
  useGetMattersForUserQuery,
} from '../services/api';
import { setSelectedMatter } from '../state/reducers/matter';
import { selectSelectedMatter } from '../state/selectors/matter-selectors';

const useMatterSelection = () => {
  const { matter_id } = useParams();
  const selectedMatter = useSelector(selectSelectedMatter);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: allMatters,
    error: allMattersError,
    isLoading: allMattersLoading,
  } = useGetMattersForUserQuery(undefined, {
    pollingInterval: 3000,
    skipPollingIfUnfocused: true,
  });

  const {
    data: selectedMatterData,
    error: selectedMatterError,
    isLoading: selectedMatterLoading,
  } = useGetMatterDetailsQuery(selectedMatter!, {
    skip: !selectedMatter,
  });

  const handleMatterChange = (id: string) => {
    dispatch(setSelectedMatter(id));
    navigate(`/matters/${id}`);
  };

  // Set the first one to be selected by default
  useEffect(() => {
    if (
      !allMattersLoading &&
      allMatters &&
      allMatters.length > 0 &&
      !selectedMatter
    ) {
      if (matter_id && allMatters.find((matter) => matter.id === matter_id)) {
        dispatch(setSelectedMatter(matter_id));
        navigate(`/matters/${matter_id}`);
      } else if (!selectedMatter) {
        dispatch(setSelectedMatter(allMatters[0].id));
        navigate(`/matters/${allMatters[0].id}`);
      }
    }
  }, [
    dispatch,
    allMattersLoading,
    allMatters,
    matter_id,
    navigate,
    selectedMatter,
  ]);

  return {
    allMatters,
    allMattersError,
    allMattersLoading,
    selectedMatterData,
    selectedMatterError,
    selectedMatterLoading,
    selectedMatter,
    handleMatterChange,
  };
};

export default useMatterSelection;
