import React, { useState } from 'react';
import { Stack, Typography, Divider } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { TertiaryButton } from '../base/Buttons';
import { Modal } from '../base/Modal';
import LoggedInUser from '../LoggedInUser';

interface ProfileSettingsModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const ProfileSettingsModal: React.FC<ProfileSettingsModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Stack direction="column" gap={4} padding={4}>
        <Typography variant="h2">Account</Typography>
        <Divider />
        <LoggedInUser />
      </Stack>
    </Modal>
  );
};

export const ProfileSettings: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <TertiaryButton disableRipple onClick={() => setIsOpen(true)}>
        <AccountCircleOutlinedIcon />
      </TertiaryButton>
      <ProfileSettingsModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
