import React from 'react';

const FinchLogoLarge: React.FC = () => {
  return (
    <svg
      width="216"
      height="56"
      viewBox="0 0 216 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="135.25" cy="50.25" r="2.25" fill="white" />
      <path
        d="M191.069 48V9.59998H196.733V25.584H196.829C198.845 22.08 201.965 20.544 205.373 20.544C210.221 20.544 214.829 23.664 214.829 31.2V48H209.117V33.696C209.117 29.28 207.485 25.92 203.405 25.92C199.565 25.92 196.733 28.944 196.733 33.888V48H191.069Z"
        fill="black"
      />
      <path
        d="M173.723 48.816C165.851 48.816 159.995 43.344 159.995 34.704C159.995 26.064 165.803 20.544 173.579 20.544C179.003 20.544 183.803 23.328 185.771 28.656L180.395 30.816C179.243 27.504 176.603 25.92 173.531 25.92C168.923 25.92 165.947 29.712 165.947 34.656C165.947 40.032 169.355 43.44 173.771 43.44C177.227 43.44 179.915 41.376 181.259 38.016L186.539 40.08C184.619 45.6 179.771 48.816 173.723 48.816Z"
        fill="black"
      />
      <path
        d="M131.209 48V21.312H136.777V25.584H136.873C138.889 22.08 142.105 20.544 145.513 20.544C150.361 20.544 154.969 23.664 154.969 31.2V48H149.257V33.696C149.257 29.28 147.625 25.92 143.545 25.92C139.705 25.92 136.873 28.896 136.873 33.888V48H131.209Z"
        fill="black"
      />
      <path
        d="M118.366 48V21.312H124.03V48H118.366ZM117.502 12.672C117.502 10.704 119.086 9.02399 121.246 9.02399C123.31 9.02399 124.894 10.704 124.894 12.672C124.894 14.784 123.31 16.464 121.246 16.464C119.086 16.464 117.502 14.784 117.502 12.672Z"
        fill="black"
      />
      <path
        d="M89.272 48V10.56H113.08V16.224H95.32C95.32 19.68 95.32 23.184 95.32 26.688H112.12V32.496H95.32C95.32 37.68 95.32 42.816 95.32 48H89.272Z"
        fill="black"
      />
      <path
        d="M37.0486 15.3353C36.1963 14.1421 34.4918 13.9716 33.469 14.9944L1.67913 46.4434C0.656394 47.4661 0.82685 49.1706 1.93481 49.9377C7.21892 53.6877 13.8667 55.2218 20.5997 54.1138C23.6679 53.6025 26.5656 52.4945 29.2077 50.8752C42.077 43.1195 44.7191 26.6705 37.0486 15.3353Z"
        fill="url(#paint0_linear_416_2220)"
      />
      <path
        d="M65.0033 17.466C63.8101 16.2728 63.8101 15.9319 63.7249 15.591C62.7874 8.9432 56.9919 4 50.2589 4C46.7646 4 43.5259 5.27842 40.9691 7.66479L39.0941 9.5398C38.327 10.3068 38.2418 11.5 38.9236 12.3523C46.935 22.9205 46.5941 37.9206 37.8157 48.0627C49.8328 48.4036 59.8044 38.6876 59.8044 26.7558C59.8044 24.7955 59.5487 22.8353 59.0374 20.9603C58.8669 20.4489 59.0374 19.9376 59.293 19.5114C59.634 19.0853 60.0601 18.8296 60.6567 18.8296H64.4919C65.0885 18.6592 65.4294 17.8921 65.0033 17.466Z"
        fill="url(#paint1_linear_416_2220)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_416_2220"
          x1="36"
          y1="14"
          x2="1"
          y2="54"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12D0AE" />
          <stop offset="1" stopColor="#03C5A2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_416_2220"
          x1="51.5047"
          y1="4"
          x2="43.0002"
          y2="45"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3E21F3" />
          <stop offset="1" stopColor="#2815A3" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FinchLogoLarge;
