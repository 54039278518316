import { useState } from 'react';

import { Divider, Skeleton, Stack, Typography } from '@mui/material';
import {
  useGetRecentEngagementLetterQuery,
  useResendEngagementLetterMutation,
} from '../../services/api/documentService';
import { SecondaryButton } from '../base/Buttons';
import { Modal } from '../base/Modal';
import { useNotification } from '../contexts/NotificationContext';
import { CreateEngagementLetter } from './CreateEngagementLetter';
import { SendEngagementLetter } from './SendEngagementLetter';

// Create Engagement Letter
const CreateEngagementLetterModal = ({
  open,
  setOpen,
  matterId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  matterId: string;
}) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Stack direction="column" gap={4} padding={4}>
        <Typography variant="h2">Create Engagement Letter</Typography>
        <Divider />
        <CreateEngagementLetter matterId={matterId} setOpen={setOpen} />
      </Stack>
    </Modal>
  );
};

const CreateEngagementLetterButton = ({ matterId }: { matterId: string }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <SecondaryButton onClick={handleOpen}>Retainer</SecondaryButton>
      <CreateEngagementLetterModal
        open={isOpen}
        setOpen={setIsOpen}
        matterId={matterId}
      />
    </>
  );
};

// Send Engagement Letter
const SendEngagementLetterModal = ({
  open,
  setOpen,
  matterId,
  engagementLetterId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  matterId: string;
  engagementLetterId: string;
}) => {
  return (
    <Modal width={800} open={open} onClose={() => setOpen(false)}>
      <SendEngagementLetter
        setOpen={setOpen}
        engagementLetterId={engagementLetterId}
        matterId={matterId}
      />
    </Modal>
  );
};

const SendEngagementLetterButton = ({
  engagementLetterId,
  matterId,
}: {
  engagementLetterId: string;
  matterId: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <SecondaryButton onClick={handleOpen}>
        Send Engagement Letter
      </SecondaryButton>
      <SendEngagementLetterModal
        open={isOpen}
        setOpen={setIsOpen}
        matterId={matterId}
        engagementLetterId={engagementLetterId}
      />
    </>
  );
};

// Resend Engagement Letter
const ResendEngagementLetterButton = ({
  matterId,
  engagementLetterId,
}: {
  matterId: string;
  engagementLetterId: string;
}) => {
  const [resendEngagementLetter, { isLoading }] =
    useResendEngagementLetterMutation();

  const notify = useNotification();

  const handleResend = async () => {
    try {
      await resendEngagementLetter({ matterId, engagementLetterId });
      notify('Engagement letter resent successfully', 'success', 3000);
    } catch (error) {
      console.error(error);
      notify('Failed to resend engagement letter', 'error', 3000);
    }
  };

  return (
    <SecondaryButton disabled={isLoading} onClick={handleResend}>
      Resend Engagement Letter
    </SecondaryButton>
  );
};

// Engagement Letter Button
export const EngagementLetterButton = ({ matterId }: { matterId: string }) => {
  const {
    data: recentEngagementLetter,
    isLoading,
    isError,
  } = useGetRecentEngagementLetterQuery(matterId);

  if (isLoading) {
    return <Skeleton variant="rectangular" height={54} width={200} />;
  }

  const buttonProps = {
    engagementLetterId: recentEngagementLetter?.id,
    matterId,
  };

  const engagementLetter = isError ? null : recentEngagementLetter;

  switch (engagementLetter?.status) {
    case 'CREATED':
      return (
        <SendEngagementLetterButton
          engagementLetterId={buttonProps.engagementLetterId!}
          matterId={buttonProps.matterId}
        />
      );
    case 'SENT':
      return (
        <ResendEngagementLetterButton
          engagementLetterId={buttonProps.engagementLetterId!}
          matterId={buttonProps.matterId}
        />
      );
    default:
      return <CreateEngagementLetterButton matterId={matterId} />;
  }
};
