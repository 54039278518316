import { Alert, AlertColor, Stack } from '@mui/material';
import React, { createContext, useCallback, useContext, useState } from 'react';

type Notification = {
  id: string;
  message: string;
  type: AlertColor;
  timeout: number;
};

const NotificationContext = createContext<
  (message: string, type: AlertColor, timeout: number) => void
>(() => {});

const NotificationItem: React.FC<{
  notification: Notification;
  timeout: number;
  onDismiss: (id: string) => void;
}> = ({ notification, timeout, onDismiss }) => {
  React.useEffect(() => {
    const timer = setTimeout(() => {
      onDismiss(notification.id);
    }, timeout);
    return () => clearTimeout(timer);
  }, [notification.id, timeout]);

  return (
    <Alert severity={notification.type} className="notification">
      {notification.message}
    </Alert>
  );
};

export const NotificationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const notify = useCallback(
    (message: string, type: AlertColor = 'info', timeout = 3000) => {
      const newNotification = {
        id: crypto.randomUUID(),
        message,
        type,
        timeout,
      };
      setNotifications((prev) => [...prev, newNotification]);
    },
    [],
  );

  const handleDismiss = (id: string) => {
    setNotifications((prev) => prev.filter((n) => n.id !== id));
  };

  return (
    <NotificationContext.Provider value={notify}>
      {children}
      <Stack
        sx={{ position: 'absolute', bottom: 20, right: 20, zIndex: 1400 }}
        spacing={2}
        direction="column-reverse"
      >
        {notifications.map((notification) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            timeout={notification.timeout}
            onDismiss={handleDismiss}
          />
        ))}
      </Stack>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
