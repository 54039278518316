import {
  BaseQueryApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';

const baseQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_APP_DJANGO_BACKEND_HOST}`,
  credentials: 'include',
  prepareHeaders: (headers) => {
    const csrftoken = Cookies.get('csrftoken');
    if (csrftoken) {
      headers.set('X-CSRFToken', csrftoken);
    }
    return headers;
  },
});

const captureSentryException = (
  baseQueryResult: { error?: FetchBaseQueryError },
  args: string | FetchArgs,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraOptions?: any,
) => {
  if (baseQueryResult.error) {
    const { error } = baseQueryResult as { error: FetchBaseQueryError };
    Sentry.captureException({
      message: 'API error',
      status: error.status,
      data: error.data,
      request: args,
      context: extraOptions,
    });
  }
};

const baseQueryWithErrorHandling = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraOptions?: any,
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (
    result.error &&
    (result.error.status === 400 || result.error.status === 403)
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorData = result.error.data as any;

    // Check if the response contains "CSRF" & delete the cookie if it does
    // and the error message isn't that the CSRF token is missing
    if (
      errorData?.detail &&
      errorData.detail.includes('CSRF') &&
      !errorData.detail.includes('missing')
    ) {
      Cookies.remove('csrftoken');
    }
  }

  if (result.error) {
    captureSentryException(result, args, extraOptions);
  }

  return result;
};

export { baseQueryWithErrorHandling };
