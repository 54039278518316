import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import ClientScreenTile from './base/ClientScreenTile';
import theme from '../theme/theme';
import {
  DetailedMatter,
  MatterSummary,
} from '../services/types/client-intake-types';
import useMatterSelection from '../hooks/useMatterSelection';

const SummaryItem: React.FC<{ title: string; children: React.ReactNode }> = ({
  title,
  children,
}) => {
  return (
    <Grid item xs={12} sm={12} lg={4} key={title}>
      <Typography variant="body1" sx={{ mb: '8px' }}>
        {title}
      </Typography>

      {children}
    </Grid>
  );
};

const Summary: React.FC<{ summary: MatterSummary }> = ({ summary }) => {
  return (
    <SummaryItem title={summary.category}>
      <Typography variant="body2" color="info.light">
        {summary.content}
      </Typography>
    </SummaryItem>
  );
};

const ActionItems: React.FC<{ matter: DetailedMatter | undefined }> = ({
  matter,
}) => {
  const actionItems = matter?.action_items.map((item) => item.content) ?? [];

  return (
    <SummaryItem title="Action Items">
      <List
        sx={{ listStyleType: 'disc', listStylePosition: 'inside', padding: 0 }}
      >
        {actionItems.length === 0 ? (
          <Typography variant="subtitle1">No action items</Typography>
        ) : (
          actionItems.map((item, index) => (
            <ListItem
              key={`${matter?.id}-${item}-${index}`}
              sx={{ padding: '8px', paddingTop: '0px', paddingY: 0 }}
            >
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="subtitle1" sx={{ display: 'list-item' }}>
                    {item}
                  </Typography>
                }
              />
            </ListItem>
          ))
        )}
      </List>
    </SummaryItem>
  );
};

const Summaries: React.FC = () => {
  const { selectedMatterData } = useMatterSelection();

  return (
    <ClientScreenTile>
      <Stack direction="column" spacing={2} sx={{ margin: 'initial' }}>
        <Box
          sx={{
            width: '100%',
            borderBottom: `1px solid ${theme.palette.border.main}`,
            paddingBottom: 3,
          }}
        >
          <Typography variant="h2">Case Summary</Typography>
        </Box>
        <Box sx={{ width: '100%', paddingTop: 3 }}>
          <Grid container spacing={4}>
            {selectedMatterData?.summaries &&
              selectedMatterData.summaries.map((summary) => (
                <Summary key={summary.id} summary={summary} />
              ))}
            <ActionItems matter={selectedMatterData} />
          </Grid>
        </Box>
      </Stack>
    </ClientScreenTile>
  );
};

export default Summaries;
