import { Button, Divider, Stack, Typography } from '@mui/material';
import {
  useGetEngagementLetterPreviewQuery,
  useSendEngagementLetterMutation,
} from '../../services/api/documentService';
import { SecondaryButton } from '../base/Buttons';
import { PDFPreview } from '../base/PDFPreview';
import { useNotification } from '../contexts/NotificationContext';

const PDF_PREVIEW_HEIGHT = 'calc(100vh - 200px)';

const EngagementLetterPreview = ({
  engagementLetterId,
  matterId,
}: {
  engagementLetterId: string;
  matterId: string;
}) => {
  const { data: pdfBlob } = useGetEngagementLetterPreviewQuery({
    matterId,
    documentInstanceId: engagementLetterId,
  });

  return (
    <PDFPreview
      blob={pdfBlob ?? null}
      width="100%"
      height={PDF_PREVIEW_HEIGHT}
    />
  );
};

export const SendEngagementLetter = ({
  setOpen,
  matterId,
  engagementLetterId,
}: {
  setOpen: (open: boolean) => void;
  matterId: string;
  engagementLetterId: string;
}) => {
  const notify = useNotification();
  const [sendEngagementLetter, { isLoading }] =
    useSendEngagementLetterMutation();

  const handleSend = async () => {
    try {
      await sendEngagementLetter({ matterId, engagementLetterId });
      notify('Engagement letter sent', 'success', 3000);
      setOpen(false);
    } catch (error) {
      notify('Failed to send engagement letter', 'error', 3000);
    }
  };

  return (
    <Stack
      maxHeight="90vh"
      alignItems="center"
      direction="column"
      gap={4}
      padding={4}
    >
      <Typography variant="h2">Engagement Letter Preview</Typography>
      <Divider flexItem />
      <EngagementLetterPreview
        engagementLetterId={engagementLetterId}
        matterId={matterId}
      />
      <Stack
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        px="40px"
      >
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <SecondaryButton onClick={handleSend} disabled={isLoading}>
          Send Engagement Letter
        </SecondaryButton>
      </Stack>
    </Stack>
  );
};
