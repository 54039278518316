import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    backgroundGray: Palette['primary'];
    border: Palette['primary'];
    tertiary: Palette['primary'];
    overlay: PaletteOptions['primary'];
  }
  interface PaletteOptions {
    backgroundGray?: PaletteOptions['primary'];
    border?: PaletteOptions['primary'];
    overlay?: PaletteOptions['primary'];
    tertiary?: PaletteOptions['primary'];
  }
  interface TypographyVariants {
    body3: React.CSSProperties;
    callout: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    callout?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    callout: true;
  }
}

const theme = createTheme({
  palette: {
    mode: 'light',

    primary: {
      main: '#3E36EF',
      light: '#F1EFFF',
      dark: '#381DD9',
      contrastText: '#fff',
    },

    secondary: {
      main: '#F1EFFF',
      light: '#F9F9FA',
      dark: '#E7E5F5',
      contrastText: '#3E36EF',
    },

    tertiary: {
      main: '#9C27B0',
      light: '#FCECFF',
      dark: '#852196',
      contrastText: '#fff',
    },

    success: {
      main: '#1A832C',
      light: '#EDFFF0',
      dark: '#156923',
      contrastText: '#fff',
    },

    error: {
      main: '#D21437',
      light: '#FFEDF0',
      dark: '#B81130',
      contrastText: '#fff',
    },

    warning: {
      main: '#C44607',
      light: '#FFF3ED',
      dark: '#AB3D06',
      contrastText: '#fff',
    },

    info: {
      dark: 'rgba(0,0,0,1.0)',
      main: 'rgba(0,0,0,0.75)',
      light: 'rgba(0,0,0,0.5)',
      contrastText: '#fff',
    },
    backgroundGray: {
      main: '#FBFBFB',
      dark: '#F9F9FA',
      light: '#FAF9F7',
      contrastText: '#000000',
    },
    border: {
      main: '#E7E7E7',
      dark: '#DCDCDC',
    },
    overlay: {
      main: 'rgba(0,0,0,0.50)',
      dark: 'rgba(0,0,0,0.85)',
    },
  },

  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: `#CCCCCC`,
          backgroundColor: `#FAFAFA`,
          borderRadius: `4px`,
          height: `48px`,
          width: `100%`,
        },
        input: {
          lineHeight: `100px`,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&.MuiInputLabel-outlined': {
            transform: 'translate(14px, 14px) scale(1)',
          },
          '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#f8feff',
            border: '1.5px solid #2e7980',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#f8feff',
            border: '1.5px solid #09181a',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#3E36EF',
          fontSize: '0.875rem',
          fontWeight: 600,
          fontFamily: 'area-normal, sans-serif;',
          lineHeight: 1.7,
          '&:hover': {
            color: '#09181A',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: ``,
    },
  },

  typography: {
    // Define typography settings
    h1: {
      fontSize: '2.5rem',
      lineHeight: 1.25,
      fontWeight: 500,
      fontFamily: 'p22-mackinac-pro, serif;',
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 700,
      fontFamily: 'area-normal, sans-serif',
      lineHeight: 1.6,
    },
    h3: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 700,
      fontFamily: 'area-normal, sans-serif',
    },
    h4: {
      fontSize: '0.875rem',
      fontWeight: 600,
      fontFamily: 'area-normal, sans-serif;',
      lineHeight: 1.25,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 700,
      fontFamily: 'area-normal, sans-serif;',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 600,
      fontFamily: 'area-normal, sans-serif;',
      lineHeight: 1.7,
    },
    body3: {
      fontSize: '0.75rem',
      fontWeight: 700,
      fontFamily: 'area-normal, sans-serif;',
      lineHeight: 1.83,
    },
    callout: {
      fontSize: '0.625rem',
      fontWeight: 700,
      fontFamily: 'area-normal, sans-serif;',
      lineHeight: 1.83,
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 600,
      color: '#808080',
      fontFamily: 'area-normal, sans-serif;',
      lineHeight: 1.42,
    },
  },
  spacing: [0, 4, 8, 16, 24, 36, 48],
});

export default theme;
