interface GenericEngagementLetterField<T> {
  id: string;
  display_name: T;
  is_required: boolean;
  default_value: string | null;
}

export type PredefinedEngagementLetterField = GenericEngagementLetterField<
  keyof typeof HumanReadableDisplayNames
>;

export type CustomEngagementLetterField = GenericEngagementLetterField<string>;

export type EngagementLetterField =
  | PredefinedEngagementLetterField
  | CustomEngagementLetterField;

export type EngagementLetterFields = EngagementLetterField[];

export const HumanReadableDisplayNames = {
  // Used in Engagement Letters only
  case_type: 'Case Type',
  client_email_address: 'Email Address',
  pre_filing_contingency_fee: 'Pre-filing contingency fee',
  post_filing_contingency_fee: 'Post-filing contingency fee',
  accident_description: 'Accident Description',

  // Used in Letters of Representation only
  insurance_company_name: 'Insurance Company Name',
  insurance_address: 'Insurance Address',
  claim_number: 'Claim Number',
  date_of_loss: 'Date of Loss',
  recipient_name: 'Recipient Name',
  your_insured: 'Your Insured',

  // Used in Engagement Letters and Letters of Rep
  client_name: 'Client Name',
} as const;

export const MandatoryEngagementLetterFields: Partial<
  Record<
    keyof typeof HumanReadableDisplayNames,
    PredefinedEngagementLetterField
  >
> = {
  client_name: {
    id: 'name',
    display_name: 'client_name',
    is_required: true,
    default_value: '',
  },
  client_email_address: {
    id: 'email',
    display_name: 'client_email_address',
    is_required: true,
    default_value: '',
  },
};
