import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { FlagRounded } from '@mui/icons-material';
import ClientScreenTile from './base/ClientScreenTile';
import { getConsiderationCategoryColor } from '../utils/color';
import {
  MatterKeyConsideration,
  MatterKeyConsiderationCategory,
} from '../services/types/client-intake-types';
import theme from '../theme/theme';
import useMatterSelection from '../hooks/useMatterSelection';

const MandatoryFlags: MatterKeyConsiderationCategory[] = [
  MatterKeyConsiderationCategory.CLEAR_LIABILITY,
  MatterKeyConsiderationCategory.SIGNIFICANT_INJURIES,
  MatterKeyConsiderationCategory.INSURANCE_COVERAGE,
];

const KeyConsiderationItem: React.FC<{
  consideration: MatterKeyConsideration;
}> = ({ consideration }) => {
  return (
    <Grid item xs={12} sm={12} md={6} lg={4} key={consideration.id}>
      <Stack direction="row" spacing={1} marginBottom={2}>
        <FlagRounded
          sx={{
            color: getConsiderationCategoryColor(consideration.severity),
            width: '20px',
          }}
        />
        <Typography
          variant="body1"
          sx={{
            mb: '16px',
            color: getConsiderationCategoryColor(consideration.severity),
          }}
        >
          {consideration.category}
        </Typography>
      </Stack>
      <Typography variant="body2" color="info.light">
        {consideration.content}
      </Typography>
    </Grid>
  );
};

const KeyConsiderations: React.FC = () => {
  const { selectedMatterData } = useMatterSelection();

  // Create a dictionary directly from key_considerations
  const keyConsiderations: Record<
    MatterKeyConsiderationCategory,
    MatterKeyConsideration
  > = (selectedMatterData?.key_considerations || []).reduce(
    (acc, consideration) => {
      acc[consideration.category] = consideration;
      return acc;
    },
    {} as Record<MatterKeyConsiderationCategory, MatterKeyConsideration>,
  );

  const mandatoryConsiderations: MatterKeyConsideration[] = MandatoryFlags.map(
    (category) => {
      const consideration = keyConsiderations[category];

      if (consideration) {
        return consideration;
      }
      return {
        id: category,
        category,
        severity: 'Gray',
        matter: '',
        content: `No significant observations for "${category}"`,
      };
    },
  );

  const nonMandatoryConsiderations = Object.values(keyConsiderations).filter(
    (consideration) => !MandatoryFlags.includes(consideration.category),
  );

  return (
    <ClientScreenTile>
      <Stack direction="column" spacing={2}>
        <Box
          sx={{
            width: '100%',
            borderBottom: `1px solid ${theme.palette.border.main}`,
            paddingBottom: 3,
            marginBottom: 3,
          }}
        >
          <Typography variant="h2">Key Considerations</Typography>
        </Box>
        <Box sx={{ width: '100%', paddingTop: 3 }}>
          <Grid container spacing={5}>
            {mandatoryConsiderations &&
              mandatoryConsiderations.map((consideration) => (
                <KeyConsiderationItem
                  key={consideration.id}
                  consideration={consideration}
                />
              ))}
            {nonMandatoryConsiderations &&
              nonMandatoryConsiderations.map((consideration) => (
                <KeyConsiderationItem
                  key={consideration.id}
                  consideration={consideration}
                />
              ))}
          </Grid>
        </Box>
      </Stack>
    </ClientScreenTile>
  );
};

export default KeyConsiderations;
