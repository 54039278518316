import { CallMade } from '@mui/icons-material';
import { Stack, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetLiveCallQuery } from '../services/api/callService';

export const ActiveCall: React.FC = () => {
  const navigate = useNavigate();
  // TODO: The API should return the userId when the user logs in
  const { data: liveCall } = useGetLiveCallQuery(
    '31a56ab2-e810-4e8b-bc72-326050f57bf7',
  );
  if (!liveCall?.call || liveCall?.call.end_timestamp) return null;
  return (
    <Stack
      direction="row"
      gap="20px"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: '300px',
        backgroundColor: '#F2F2F2',
        borderRadius: '8px',
        height: '50px',
        padding: '16px',
      }}
    >
      <Typography variant="body1">
        {`Incoming call from ${liveCall?.call?.client_number}`}
      </Typography>
      <IconButton
        disableRipple
        sx={{ svg: { fill: '#000000' } }}
        onClick={() => navigate(`/call/${liveCall?.call?.id}`)}
      >
        <CallMade fontSize="medium" />
      </IconButton>
    </Stack>
  );
};
