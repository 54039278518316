import React from 'react';

const FinchBird: React.FC = () => {
  return (
    <svg
      width="65"
      height="51"
      viewBox="0 0 65 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.0486 11.3353C35.1963 10.1421 33.4918 9.97163 32.469 10.9944L0.679126 42.4434C-0.343606 43.4661 -0.17315 45.1706 0.934809 45.9377C6.21892 49.6877 12.8667 51.2218 19.5997 50.1138C22.6679 49.6025 25.5656 48.4945 28.2077 46.8752C41.077 39.1195 43.7191 22.6705 36.0486 11.3353Z"
        fill="url(#paint0_linear_416_2230)"
      />
      <path
        d="M64.0033 13.466C62.8101 12.2728 62.8101 11.9319 62.7249 11.591C61.7874 4.9432 55.9919 0 49.2589 0C45.7646 0 42.5259 1.27842 39.9691 3.66479L38.0941 5.5398C37.327 6.30685 37.2418 7.50003 37.9236 8.35231C45.935 18.9205 45.5941 33.9206 36.8157 44.0627C48.8328 44.4036 58.8044 34.6876 58.8044 22.7558C58.8044 20.7955 58.5487 18.8353 58.0374 16.9603C57.8669 16.4489 58.0374 15.9376 58.293 15.5114C58.634 15.0853 59.0601 14.8296 59.6567 14.8296H63.4919C64.0885 14.6592 64.4294 13.8921 64.0033 13.466Z"
        fill="url(#paint1_linear_416_2230)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_416_2230"
          x1="35"
          y1="9.99999"
          x2="2.60066e-06"
          y2="50"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12D0AE" />
          <stop offset="1" stopColor="#03C5A2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_416_2230"
          x1="50.5047"
          y1="0"
          x2="42.0002"
          y2="41"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3E21F3" />
          <stop offset="1" stopColor="#2815A3" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FinchBird;
