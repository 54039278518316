import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MatterState {
  selectedMatter: string | null;
}

const initialState: MatterState = {
  selectedMatter: null,
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setSelectedMatter(state, action: PayloadAction<string>) {
      state.selectedMatter = action.payload;
    },
  },
});

export const { setSelectedMatter } = clientsSlice.actions;

export default clientsSlice.reducer;
