import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton, Stack, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../services/api/userService';
import { userLoggedOut } from '../state/reducers/root';
import { selectEmail } from '../state/selectors/root-selectors';

const LoggedInUser: React.FC = () => {
  const email = useSelector(selectEmail);
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await logout().unwrap();
      dispatch(userLoggedOut());
      navigate('/login'); // Redirect to the login page after logout
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <Stack
      direction="row"
      gap="20px"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        backgroundColor: '#F2F2F2',
        borderRadius: '8px',
        height: '50px',
        padding: '16px',
      }}
    >
      <Typography variant="body1">{email}</Typography>
      <IconButton
        onClick={handleLogout}
        disableRipple
        sx={{ svg: { fill: '#000000' } }}
      >
        <LogoutIcon fontSize="medium" />
      </IconButton>
    </Stack>
  );
};

export default LoggedInUser;
