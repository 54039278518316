import React from 'react';

const FinchLogo = ({ height = '135px', width = '627px' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 86 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_41_7868)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0672 4.06135C12.5063 3.22981 12.5017 3.23437 11.7961 3.93992L11.7961 3.93992L0.50759 15.1421C-0.181811 15.8315 -0.181775 15.8315 0.586365 16.3783L0.598383 16.3869C0.799484 16.53 1.00615 16.6641 1.21781 16.789L6.42188 11.5849L6.57752 12.4356L1.87419 17.1389C2.13421 17.2634 2.40035 17.3749 2.67172 17.473L6.42188 13.7229L6.57752 14.5735L3.4396 17.7114C4.64483 18.0259 5.9311 18.0882 7.22625 17.8744C8.31578 17.6923 9.34474 17.2976 10.2829 16.7208C14.8529 13.9582 15.791 8.09901 13.0672 4.06135Z"
        fill="#3E36EF"
      />
      <path
        d="M22.7415 4.79662C22.3177 4.37161 22.3177 4.25017 22.2875 4.12874C21.9546 1.76079 19.8967 0 17.5058 0C16.2649 0 15.1149 0.455376 14.207 1.30541L13.5411 1.97329C12.8568 2.62677 13.0389 2.39059 13.4806 2.97512C16.3254 6.73956 16.2044 12.0827 13.0872 15.6953C16.707 16.5529 20.8954 12.3558 20.8954 8.10568C20.8954 7.40744 20.8046 6.7092 20.623 6.04132C20.5624 5.85917 20.623 5.67701 20.7138 5.52522C20.8348 5.37343 20.9861 5.28236 21.198 5.28236H22.5599C23.1852 5.28236 23.1845 5.24102 22.7415 4.79662Z"
        fill="#3E36EF"
      />
      <path
        d="M32.9 17V1.4H42.7V3.42H35.04C35.04 5.02 35.04 6.64 35.04 8.24H42.3V10.3H35.04C35.04 12.54 35.04 14.76 35.04 17H32.9ZM44.9823 17V5.9H47.0223V17H44.9823ZM44.6023 2.18C44.6023 1.44 45.2023 0.799999 46.0223 0.799999C46.8023 0.799999 47.4023 1.44 47.4023 2.18C47.4023 3 46.8023 3.62 46.0223 3.62C45.2023 3.62 44.6023 3 44.6023 2.18ZM50.1777 17V5.9H52.1777V7.8H52.2177C53.0577 6.26 54.4177 5.58 55.9177 5.58C57.9177 5.58 59.8777 6.82 59.8777 10.02V17H57.8377V10.92C57.8377 8.76 56.9177 7.5 55.2577 7.5C53.4177 7.5 52.2177 9 52.2177 11.04V17H50.1777ZM67.7525 17.34C64.5125 17.34 62.1125 15.04 62.1125 11.48C62.1125 7.92 64.4925 5.58 67.7125 5.58C69.8925 5.58 71.8525 6.66 72.6925 8.88L70.7725 9.64C70.2725 8.22 69.0925 7.5 67.6725 7.5C65.5925 7.5 64.2325 9.2 64.2325 11.46C64.2325 13.86 65.7725 15.44 67.7925 15.44C69.4125 15.44 70.6125 14.46 71.1925 12.96L73.0725 13.7C72.2325 16.02 70.2325 17.34 67.7525 17.34ZM75.08 17V1H77.12V7.8H77.16C78 6.26 79.32 5.58 80.8 5.58C82.8 5.58 84.78 6.82 84.78 10.02V17H82.74V10.92C82.74 8.76 81.82 7.5 80.16 7.5C78.32 7.5 77.12 9 77.12 11.04V17H75.08Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_41_7868">
        <rect width="86" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FinchLogo;
