import { RootState } from '../state';

const selectUser = (state: RootState) => state.root.user;

const selectEmail = (state: RootState) => state.root.user?.email;

const selectLoggedIn = (state: RootState) => state.root.loggedIn;

const selectRedirectPath = (state: RootState) => state.root.redirectPath;
export { selectEmail, selectLoggedIn, selectRedirectPath, selectUser };
