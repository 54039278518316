import {
  EngagementLetterFields,
  HumanReadableDisplayNames,
  MandatoryEngagementLetterFields,
} from '../services/types/document-types';
import { clone } from './common';

export const populateEngagementFieldsWithDefaults = (
  engagementFields: EngagementLetterFields,
): EngagementLetterFields => {
  // Clone the mandatory fields to avoid mutating the original object
  const mandatoryFields = clone(MandatoryEngagementLetterFields);

  // For each engagement field that matches a mandatory field,
  // update the mandatory field's default value
  engagementFields.forEach((field) => {
    if (field.display_name in mandatoryFields) {
      const displayName = field.display_name as keyof typeof mandatoryFields;
      mandatoryFields[displayName] = {
        ...mandatoryFields[displayName]!,
        default_value: field.default_value,
      };
    }
  });

  // Filter out any fields that are already covered by mandatory fields
  const customFields = engagementFields.filter(
    (field) => !(field.display_name in mandatoryFields),
  );

  // Combine mandatory fields with custom fields
  return [...Object.values(mandatoryFields), ...customFields];
};

export const getHumanReadableFieldName = (fieldName: string) => {
  return fieldName in HumanReadableDisplayNames
    ? HumanReadableDisplayNames[
        fieldName as keyof typeof HumanReadableDisplayNames
      ]
    : fieldName;
};
