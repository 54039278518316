export const isPhoneNumberValid = (phoneNumber: string) => {
  // Accepting flexible phone number formats like (303) 217-0796 or 303-217-0796
  const phonePattern = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  return phonePattern.test(phoneNumber);
};

export const sanitizePhoneNumber = (phoneNumber: string) => {
  return `+1${phoneNumber.replace(/\D/g, '')}`;
};

export const isEmailValid = (email: string) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};
