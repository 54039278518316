import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithErrorHandling } from './baseQuery';
import { WS_BASE_URL } from '../../utils/constants';

interface Call {
  id: string;
  start_timestamp: any;
  end_timestamp: any;
  twilio_sid_incoming_call: string;
  attorney_number: string;
  client_number: string;
}

export interface TranscriptSegment {
  text: string;
  start_time: number;
  end_time: number;
}

interface LiveCallResponse {
  call?: Call;
}

type LiveTranscriptResponse = {
  call?: Call;
  transcript_segments: TranscriptSegment[];
};

export const callApiClient = createApi({
  reducerPath: 'callApi',
  baseQuery: baseQueryWithErrorHandling,
  // When fetching like this there is a race condition and you can get potentially stale data
  // Ideally you:
  // 1. Fetch the  data via the websocket
  // 2. Fetch the data via the api
  // 3. Use something like message ordering to ensure that you apply updates in the correct order
  endpoints: (build) => ({
    getLiveCall: build.query<LiveCallResponse, string>({
      // In theory the websocket on the server should be able to get the userId from the session auth as well
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: (userId) => `api/call/live/`,
      async onCacheEntryAdded(
        userId,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        // create a websocket connection when the cache subscription starts
        const ws = new WebSocket(`${WS_BASE_URL}/ws/notifier/${userId}/`);
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          // when data is received from the socket connection to the server,
          // if it is a message and for the appropriate channel,
          // update our query result with the received message
          const listener = (event: MessageEvent) => {
            const data = JSON.parse(event.data);
            updateCachedData((draft) => {
              draft.call = { ...data };
            });
          };

          ws.addEventListener('message', listener);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
        }
        await cacheEntryRemoved;
        ws.close();
      },
    }),

    getLiveTranscript: build.query<LiveTranscriptResponse, string>({
      query: (conversationId) => `api/transcript/${conversationId}/live/`,
      transformResponse: (response: any) => {
        return {
          call: undefined,
          transcript_segments: response,
        };
      },
      async onCacheEntryAdded(
        conversationId,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        // create a websocket connection when the cache subscription starts
        const ws = new WebSocket(`${WS_BASE_URL}/ws/call/${conversationId}/`);
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          // when data is received from the socket connection to the server,
          // if it is a message and for the appropriate channel,
          // update our query result with the received message
          const listener = (event: MessageEvent) => {
            const data = JSON.parse(event.data);
            updateCachedData((draft) => {
              if (data.type === 'transcription_segment_added') {
                draft.transcript_segments.push(data.payload);
              } else if (data.type === 'call_updated') {
                draft.call = data.payload;
              }
            });
          };

          ws.addEventListener('message', listener);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
        }
        await cacheEntryRemoved;
        ws.close();
      },
    }),
  }),
});

export const { useGetLiveCallQuery, useGetLiveTranscriptQuery } = callApiClient;
