import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCheckSessionQuery } from '../../services/api/userService';
import { userLoggedIn, userLoggedOut } from '../../state/reducers/root';

interface SessionManagerProps {
  children: React.ReactElement;
}

const SessionManager: React.FC<SessionManagerProps> = ({ children }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { data, error } = useCheckSessionQuery();

  useEffect(() => {
    if (data?.is_valid) {
      dispatch(userLoggedIn(data.user));
    } else if (error) {
      dispatch(userLoggedOut());
    }
    setIsLoading(false);
  }, [dispatch, data, error]);

  if (isLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  return children;
};

export default SessionManager;
