import AddIcon from '@mui/icons-material/Add';
import {
  Divider,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useMatterSelection from '../../hooks/useMatterSelection';
import { useCreateMatterMutation } from '../../services/api';
import { useGetFormCategoriesQuery } from '../../services/api/formsService';
import { selectUser } from '../../state/selectors/root-selectors';
import {
  isPhoneNumberValid,
  sanitizePhoneNumber,
} from '../../utils/validation';
import { SecondaryButton, TertiaryButton } from '../base/Buttons';
import { Modal } from '../base/Modal';
import { useNotification } from '../contexts/NotificationContext';

const FormCategorySelect: React.FC<{
  onChange: (category: string) => void;
}> = ({ onChange }) => {
  const { data: formCategories } = useGetFormCategoriesQuery();

  return (
    <Select
      margin="none"
      defaultValue=""
      onChange={(e) => onChange(e.target.value)}
      required
    >
      {formCategories?.map((category) => (
        <MenuItem key={category} value={category}>
          {category}
        </MenuItem>
      ))}
    </Select>
  );
};

interface CreateMatterFormState {
  name: string;
  phoneNumber: string;
  category: string;
}

const CreateMatterForm: React.FC<{
  onSubmit: (data: CreateMatterFormState) => void;
}> = ({ onSubmit }) => {
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [formState, setFormState] = useState<CreateMatterFormState>({
    name: '',
    phoneNumber: '',
    category: '',
  });

  const isFormValid =
    formState.phoneNumber && formState.name && formState.category;

  const handleChange = (key: keyof CreateMatterFormState, value: string) => {
    setFormState({ ...formState, [key]: value });
  };

  const handleSubmit = () => {
    if (!isPhoneNumberValid(formState.phoneNumber)) {
      setPhoneNumberError(true);
      return;
    }

    onSubmit(formState);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (phoneNumberError && isPhoneNumberValid(e.target.value)) {
      setPhoneNumberError(false);
    }
    handleChange('phoneNumber', e.target.value);
  };

  return (
    <Stack gap={4}>
      <TextField
        margin="none"
        required
        fullWidth
        name="phoneNumber"
        label="Phone Number"
        type="tel"
        id="phoneNumber"
        value={formState.phoneNumber}
        onChange={handlePhoneNumberChange}
        error={!!phoneNumberError}
        helperText={phoneNumberError || ''}
      />
      <TextField
        margin="none"
        fullWidth
        name="name"
        label="Matter Name"
        type="text"
        id="name"
        value={formState.name}
        onChange={(e) => handleChange('name', e.target.value)}
      />

      <FormCategorySelect
        onChange={(category) => handleChange('category', category)}
      />

      <SecondaryButton disabled={!isFormValid} onClick={handleSubmit}>
        Submit
      </SecondaryButton>
    </Stack>
  );
};

const CreateMatterModal: React.FC<{
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
  const [createMatter] = useCreateMatterMutation();
  const notify = useNotification();
  const user = useSelector(selectUser);
  const { handleMatterChange } = useMatterSelection();

  const handleSubmit = async (data: CreateMatterFormState) => {
    if (!user?.firm) {
      notify('User must be in a firm to create a matter', 'error', 3000);
      return;
    }

    const result = await createMatter({
      matter: {
        firm: user.firm,
        creator: user.id,
        name: data.name,
        type: data.category,
        clients: [
          {
            phone_numbers: [
              { phone_number: sanitizePhoneNumber(data.phoneNumber) },
            ],
          },
        ],
      },
      form_category: data.category,
    });

    if (result.data) {
      notify(
        `Matter ${result.data.matter.name} created successfully`,
        'success',
        3000,
      );
      handleMatterChange(result.data.matter.id);
      setIsOpen(false);
    } else {
      notify(`Failed to create matter ${data.name}`, 'error', 3000);
    }
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <Stack direction="column" gap={4} padding={4}>
        <Typography variant="h2">Create Matter</Typography>
        <Divider />
        <CreateMatterForm onSubmit={handleSubmit} />
      </Stack>
    </Modal>
  );
};

export const CreateMatter: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TertiaryButton disableRipple onClick={() => setIsOpen(true)}>
        <AddIcon />
      </TertiaryButton>
      <CreateMatterModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
