import { Menu } from '@mui/icons-material';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import useMatterSelection from '../hooks/useMatterSelection';
import theme from '../theme/theme';
import { ActiveCall } from './ActiveCall';
import FinchLogo from './base/FinchLogo';
import { CreateMatter } from './sidebar/CreateMatter';
import { ProfileSettings } from './sidebar/ProfileSettings';

const DRAWER_WIDTH = 280;

const SidebarContent: React.FC = () => {
  const {
    allMatters,
    allMattersError,
    selectedMatter,
    selectedMatterError,
    handleMatterChange,
  } = useMatterSelection();

  return (
    <Box
      sx={{
        width: '280px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'backgroundGray.light',
        height: '100%',
      }}
    >
      <Box
        pt={6}
        px={4}
        sx={{
          borderRight: `1px solid ${theme.palette.border.main}`,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingBottom: 3,
          }}
        >
          <FinchLogo height="18px" width="86px" />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing="4px"
            marginRight={-2}
          >
            <ProfileSettings />
            <CreateMatter />
          </Stack>
        </Stack>
      </Box>

      <Box sx={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}>
        {allMattersError || selectedMatterError || allMatters?.length === 0 ? (
          <Typography variant="body1" mt="16px">
            Your client calls will appear here.
          </Typography>
        ) : (
          <List sx={{ paddingTop: 0, cursor: 'pointer' }}>
            {allMatters &&
              allMatters.map((matter) => (
                <ListItemButton
                  disableRipple
                  key={matter.id}
                  sx={{
                    paddingX: 4,
                    width: '100%',
                    height: '78px',
                    borderRight: `1px solid ${theme.palette.border.main}`,
                    cursor: 'pointer',
                    '&.Mui-selected': {
                      backgroundColor: 'white',
                      borderTop: `1px solid ${theme.palette.border.dark}`,
                      borderBottom: `1px solid ${theme.palette.border.dark}`,
                      borderRight: `1px solid white`,
                      '&:hover': {
                        backgroundColor: 'white',
                      },
                    },
                    '&.Mui-hover': {
                      backgroundColor: 'border.main',
                    },
                  }}
                  onClick={() => handleMatterChange(matter.id)}
                  selected={selectedMatter === matter.id}
                >
                  <ListItemText
                    key={`${matter.id}-${matter.name}`}
                    primary={
                      <Typography variant="h3" mb="2px">
                        {matter.name}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body3" color="info.light">
                        {matter.status}
                      </Typography>
                    }
                  />
                  {/* <ListItemSecondaryAction>
                    <ArrowForwardIcon sx={{ color: '#7a7a7a' }} />
                  </ListItemSecondaryAction> */}
                </ListItemButton>
              ))}
          </List>
        )}
      </Box>
      <ActiveCall />
    </Box>
  );
};

export const SidebarMenuButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <IconButton
      sx={{
        position: 'absolute',
        top: 8,
        left: 16,
      }}
      color="inherit"
      aria-label="open drawer"
      edge="start"
      onClick={onClick}
    >
      <Menu sx={{ fontSize: '32px' }} />
    </IconButton>
  );
};

export const Sidebar: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      {isMobile && <SidebarMenuButton onClick={handleDrawerToggle} />}
      <Box
        component="nav"
        sx={{ width: { md: DRAWER_WIDTH }, flexShrink: { md: 0 } }}
      >
        <Drawer
          sx={{ overflowX: 'hidden' }}
          variant={isMobile ? 'temporary' : 'permanent'}
          open={isMobile ? mobileOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <SidebarContent />
        </Drawer>
      </Box>
    </>
  );
};
