import {
  Modal as MuiModal,
  ModalProps as MuiModalProps,
  Paper,
} from '@mui/material';

interface ModalProps extends MuiModalProps {
  onClose: () => void;
  open: boolean;
  width?: number;
  minHeight?: number;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};

export const Modal: React.FC<ModalProps> = ({
  children,
  width,
  minHeight,
  ...props
}) => {
  return (
    <MuiModal {...props}>
      <Paper sx={{ ...style, width: width ?? style.width }}>{children}</Paper>
    </MuiModal>
  );
};
