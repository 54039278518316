const suppressedErrors =
  /ResizeObserver loop completed with undelivered notifications/;

window.addEventListener('error', (event: ErrorEvent) => {
  if (suppressedErrors.test(event.message)) {
    event.stopImmediatePropagation();
  }
});

window.addEventListener(
  'unhandledrejection',
  (event: PromiseRejectionEvent) => {
    if (event.reason && suppressedErrors.test(event.reason.message)) {
      event.preventDefault();
    }
  },
);

export {};
