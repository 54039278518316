import React from 'react';
import { Box, Typography, Paper, List, ListItem, Divider } from '@mui/material';
import { TranscriptSegment } from '../../services/api/callService';

const Transcript = ({ transcript }: { transcript: TranscriptSegment[] }) => {
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <Paper elevation={3}>
      <Box p={2}>
        <Typography variant="h5" gutterBottom>
          Transcript
        </Typography>
        <List>
          {transcript.map((segment, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    {formatTime(segment.start_time)} -{' '}
                    {formatTime(segment.end_time)}
                  </Typography>
                  <Typography variant="body1">{segment.text}</Typography>
                </Box>
              </ListItem>
              {index < transcript.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Paper>
  );
};

export default Transcript;
