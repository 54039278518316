import { createApi } from '@reduxjs/toolkit/query/react';
import { User } from '../types/user-types';
import { baseQueryWithErrorHandling } from './baseQuery';

interface UserCredentials {
  email?: string;
  password1: string;
  password2?: string;
  first_name?: string;
  last_name?: string;
  token?: string;
  phone_number?: string;
}

interface LoginCredentials {
  username: string;
  password: string;
}

export interface UserLoginResponse {
  success: string;
  session_id: string;
  user: User;
}

export const userApiClient = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWithErrorHandling,
  endpoints: (builder) => ({
    signupUser: builder.mutation<UserLoginResponse, UserCredentials>({
      query: (credentials) => ({
        url: 'api/signup/',
        method: 'POST',
        body: credentials,
        headers: {
          // Add your headers here
        },
      }),
    }),
    loginUser: builder.mutation<UserLoginResponse, LoginCredentials>({
      query: ({ username, password }) => ({
        url: 'api/login/',
        method: 'POST',
        body: {
          username,
          password,
        },
      }),
    }),
    checkSession: builder.query<
      { is_valid: boolean; email: string; user: User },
      void
    >({
      query: () => ({
        url: 'api/check-session/',
        method: 'GET',
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: 'api/logout/',
        method: 'POST',
        credentials: 'include',
      }),
    }),
    requestResetPassword: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        url: 'api/request-reset-password/',
        method: 'POST',
        body: { email },
      }),
    }),
    resetPassword: builder.mutation<void, { password: string; token: string }>({
      query: ({ password, token }) => ({
        url: 'api/reset-password/',
        method: 'POST',
        body: { password, token },
      }),
    }),
  }),
});

export const {
  useSignupUserMutation,
  useLoginUserMutation,
  useCheckSessionQuery,
  useLogoutMutation,
  useRequestResetPasswordMutation,
  useResetPasswordMutation,
} = userApiClient;
