import { MatterKeyConsiderationSeverity } from '../services/types/client-intake-types';
import theme from '../theme/theme';

export function getConsiderationStatusColor(value: string): string {
  switch (value) {
    case 'Unclear':
      return '#404040';
    case 'Present':
      return '#2A7410';
    default:
      return '#000000'; // Default color if none of the cases match
  }
}

export function getConsiderationCategoryColor(
  severity: MatterKeyConsiderationSeverity,
): string {
  switch (severity) {
    case 'Green':
      return theme.palette.success.main;
    case 'Yellow':
      return theme.palette.warning.main;
    case 'Red':
      return theme.palette.error.main;
    default:
      return '#000000'; // Default color if none of the cases match
  }
}
