import styled from '@emotion/styled';
import { Button } from '@mui/material';

const PrimaryButton = styled(Button)({
  textDecoration: 'none',
  textTransform: 'none',
  backgroundColor: 'primary.main',
  fontSize: '1rem',
  fontFamily: 'area-normal, sans-serif',
  fontWeight: 700,
  borderRadius: '4px',
  lineHeight: 'normal',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

PrimaryButton.defaultProps = {
  variant: 'contained',
};

const SecondaryButton = styled(Button)({
  textDecoration: 'none',
  textTransform: 'none',
  backgroundColor: 'primary.light !important',
  fontSize: '1rem',
  fontFamily: 'area-normal, sans-serif',
  fontWeight: 700,
  borderRadius: '4px',
  lineHeight: 'normal',
  minHeight: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: 'none',
  letterSpacing: '0px',
  '&:hover': {
    boxShadow: 'none',
  },
  '&:disabled': {
    backgroundColor: '#E0E0E0',
    color: '#9E9E9E',
  },
});

SecondaryButton.defaultProps = {
  variant: 'contained',
  color: 'secondary',
};

const TertiaryButton = styled(Button)({
  textDecoration: 'none',
  textTransform: 'none',
  backgroundColor: 'primary.main',
  fontSize: '1rem',
  fontFamily: 'area-normal, sans-serif',
  fontWeight: 700,
  borderRadius: '100px',
  lineHeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '8px',
  paddingRight: '8px',
  paddingTop: '8px',
  paddingBottom: '8px',
  minWidth: '0px',
  marginRight: '-8px',
});

const TealIconButton = styled(Button)({
  svg: {
    fill: '#03C5A2',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export { PrimaryButton, SecondaryButton, TealIconButton, TertiaryButton };
