import { Stack, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import useMatterSelection from '../hooks/useMatterSelection';
import { usePatchMatterMutation } from '../services/api';
import EditableTypography from './base/EditableTypography';
import { EngagementLetterButton } from './engagement-letter/EngagementLetter';

const MatterTitle: React.FC = () => {
  const { selectedMatterData: matterData } = useMatterSelection();

  const [patchMatterMutation] = usePatchMatterMutation();

  const [name, setName] = useState(matterData?.name || '');

  useEffect(() => {
    setName(matterData?.name || '');
  }, [matterData?.name]);

  const handleNameChange = async (matterId: string, updatedValue: string) => {
    if (updatedValue === matterData?.name) {
      return;
    }
    try {
      await patchMatterMutation({
        matter_id: matterId,
        patch: { name: updatedValue },
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <div>
      <Stack
        direction="row"
        gap="24px"
        marginBottom={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="column" spacing={0}>
          {matterData?.id && (
            <EditableTypography
              variant="h1"
              style={{}}
              value={name}
              onBlur={() => handleNameChange(matterData?.id, name)}
              onChange={(value) => setName(value as string)}
            />
          )}
          <Typography variant="h4" color="info.light">
            {matterData?.type}
          </Typography>
        </Stack>
        <Stack direction="row" gap="24px">
          {matterData?.id && (
            <EngagementLetterButton matterId={matterData?.id} />
          )}
          {/* <SecondaryButton disableRipple onClick={handleRequestMedicalRecords}>
            {medicalRecordButtonText}
          </SecondaryButton> */}
        </Stack>
      </Stack>
    </div>
  );
};

export default MatterTitle;
