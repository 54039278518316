import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

type ClientScreenTileProps = {
  children: ReactNode;
};

const ClientScreenTile: React.FC<ClientScreenTileProps> = ({ children }) => {
  return <Box>{children}</Box>;
};

export default ClientScreenTile;
