import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import Typography, { TypographyProps } from '@mui/material/Typography';
import * as React from 'react';

export interface EditableTypographyProps {
  /**
   * Handler called when the value changes
   */
  onChange: (value: string) => void;
  value: string;
}

const EditableTypography = ({
  onChange: propsOnChange,
  value,
  ...props
}: EditableTypographyProps & TypographyProps & InputBaseProps) => {
  const onChange = (updatedValue: string) => {
    if (propsOnChange) {
      propsOnChange(updatedValue);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <Typography
      {...props}
      component={InputBase}
      onChange={handleChange}
      value={value}
    />
  );
};

export default EditableTypography;
