import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import {
  selectLoggedIn,
  selectRedirectPath,
} from '../../state/selectors/root-selectors';
import { setRedirectPath } from '../../state/reducers/root';

const ProtectedRoute: React.FC = () => {
  const loggedIn = useSelector(selectLoggedIn);
  const redirectPath = useSelector(selectRedirectPath);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!loggedIn && !redirectPath) {
      dispatch(setRedirectPath(location.pathname));
    }
  }, [loggedIn, redirectPath, location.pathname, dispatch]);

  if (loggedIn) {
    return <Outlet />;
  }

  return <Navigate to="/login" replace />;
};

export default ProtectedRoute;
